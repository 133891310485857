<template>
    <b-col cols="6" class="d-flex justify-content-end">
        <b-input-group class="input-group-merge" style="margin: auto; margin-left: 20px;">
            <b-form-input
                v-if="!show"
                type="search"
                placeholder="ابحث عن تصنيف محدد"
                v-model="categoryFilter.search"
                @input="filterDataCategory(1)"
            />
            <b-form-input
                v-else
                type="search"
                placeholder="ابحث عن تصنيف فرعي محدد"
                v-model="subCategoryFilter.search"
                @input="filterDataSubCategory(1)"
            />
            <b-input-group-append is-text>
                <unicon width="16" height="16" fill="gray" name="search" />
            </b-input-group-append>
        </b-input-group>
         <b-button size="sm" variant="primary" class="text-nowrap" @click="setIsCreateCategorieModal(true);resetCategoriesDto()"> 
            تصنيف جديد
            <unicon class="ml-1" name="plus" width="18" height="18" fill="#fff" />
        </b-button>
        <createCategorieModal />
    </b-col>
</template>
<script>
import createCategorieModal from "./create-categorie-modal.vue";
import { mapActions, mapGetters } from 'vuex';
export default {
    components: {
        createCategorieModal
    },
    computed: {
        ...mapGetters(["categoryFilter", "subCategoryFilter", "show"])
    },
    methods: {
        ...mapActions(["setIsCreateCategorieModal", "resetCategoriesDto", "filterDataCategory", "filterDataSubCategory"])
    }
}
</script>