<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form @submit.prevent="onSubmit">
        <b-sidebar bg-variant="white" v-model="is" backdrop shadow>
          <template #header>
            <div
              class="d-flex justify-content-between align-items-center px-1 py-50 bg-light"
            >
              <h5 class="m-0">
                <strong>{{categoryDto.id ? 'تعديل' : 'إضافة'}} تصنيف</strong>
              </h5>
              <b-button
                @click="is = false"
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
              >
                <unicon name="times" width="16" height="16" />
              </b-button>
            </div>
          </template>
          <b-col>
            <BTextInputWithValidation
              rules="required"
              label="اسم التصنيف"
              name="اسم التصنيف"
              v-model="categoryDto.name"
              placeholder="أدخل اسم التصنيف"
            />

            <validation-provider
              name="nameCategories"
              rules="required"
              v-if="show"
            >
              <v-select
                :options="categoriesList"
                label="name"
                :reduce="(item) => item.id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                multiple
                v-model="categoryDto.mainCategoriesIds"
              ></v-select>
            </validation-provider>
            <label v-if="!show">صورة</label>
            <fileDragDrop
              v-if="!show"
              @inValidFile="unValidThumbImage"
              @uploaded="uploadThumbImage"
              id="employee-photo"
              height="100px"
              type="image"
              title="صورة جديدة"
            />
            <img
              v-if="
                !show &&
                  (categoryDto.imageDto.base64 || categoryDto.imageDto.path)
              "
              :src="
                !categoryDto.imageDto.base64
                  ? categoryDto.imageDto.path.replace(
                      '~',
                      $store.getters['app/domainHost']
                    )
                  : categoryDto.imageDto.base64
              "
              style="max-height: 320px; object-fit: fill;"
              class="w-100"
            />
            <small class="text-danger" v-if="!show">
              {{
                !categoryDto.imageDto.base64 &
                !categoryDto.imageDto.path
                  ? "صورة التصنيف مطلوبة"
                  : ""
              }}
            </small>
          </b-col>
          <template #footer>
            <div class="d-flex p-1 border-top">
              <b-button
              v-if="categoryDto.id > 0"
                variant="outline-danger"
                @click="deleteCategories(categoryDto)"
                >حذف</b-button
              >
              <b-button variant="outline-primary" @click="is=false;" class="ml-auto mr-1"
                >إلغاء</b-button
              >
              <b-button variant="primary" type="submit">تم</b-button>
            </div>
          </template>
        </b-sidebar>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
  import fileDragDrop from "@core/components/file-drag-drop/file-drag-drop.vue";
  import { ValidationObserver } from "vee-validate";
  import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
  import vSelect from "vue-select";
  import { ValidationProvider } from "vee-validate";
  import { mapActions, mapGetters } from "vuex";
  export default {
    name: "BootstrapForm",
    components: {
      fileDragDrop,
      ValidationObserver,
      BTextInputWithValidation,
      vSelect,
      ValidationProvider,
    },
    data: () => ({}),

    computed: {
      is: {
        get() {
          return this.$store.getters["isCreateCategorieModal"];
        },
        set(is) {
          this.$store.dispatch("setIsCreateCategorieModal", is);
        },
      },
      ...mapGetters(["show", "categoriesList", "categoryDto"]),
    },
    methods: {
      ...mapActions(["setOrUpdateCategories", "resetCategoriesDto", "deleteCategories"]),

      uploadThumbImage(file) {
          this.categoryDto.imageDto.base64 = file[0].base64;
      },
      unValidThumbImage() {
        console.log("invalid Thumb file");
      },
      onSubmit() {
        this.setOrUpdateCategories(this.categoryDto);
      },
    },
  };
</script>
